import React from 'react';
import Layout from '../components/layouts/Layout';
import ForgotPasswordComponent from '../components/ForgotPassword';
import SEO from '../components/seo/SEO';

const ForgotPassword: React.FC = () => {
    return (
        <Layout customSEO>
            <SEO title={'Forgot Password'} />
            <ForgotPasswordComponent />
        </Layout>
    );
};

export default ForgotPassword;
