import { Alert } from 'antd';
import { Link } from 'gatsby';
import Icon from '@mdi/react';
import Spinner from './Spinner';
import styled from 'styled-components';
import React, { useState } from 'react';
import CustomInput from './CustomInput';
import { gradient, grey } from '../util/theme';
import { useForm } from "react-hook-form";
import { Button } from '@material-ui/core';
import { mdiCheckCircleOutline } from '@mdi/js';
import axios, { handleError } from '../util/axios';
import { modularScale } from '../util/modularScale';

const Container = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 4px;
	
	@media (min-width: 450px) {
	    padding: 4rem;
	}
	
	@media (min-width: 900px) {
	    padding: 4rem;
	}
`;

const InputError = styled.span`
	color: red;
    font-size: ${modularScale(-1)};
`;

const Input = styled(CustomInput)`
	margin-bottom: 1rem;
`;

const Title = styled.h2`
	font-size: ${modularScale(1)};
	font-weight: bold;
	margin: 1rem 0 1rem;
	line-height: 1;
	
	@media (min-width: 900px) {
        margin: 0 0 1rem;
	}
`;

const Info = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
    color: #fff !important;
	background-color: #000;
	height: 42px;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
	    background-color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	
	svg {
	    width: 18px;
	    margin-left: 0.5rem;
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 1rem;
`;

const Help = styled.p`
	font-size: 12px;
	color: ${grey};
	margin-top: 1rem;
`;

const ForgotPasswordForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [requestError, setRequestError] = useState('');
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async data => {
        setIsLoading(true);
        setIsDisabled(true);

        try {
            await axios.post('/password/reset/email', data);
            setIsSuccess(true);
        } catch (err) {
            handleError(err, () => {
                if (err.response.status === 400) {
                    setRequestError('No account exists for provided email.');
                    setIsDisabled(false);
                    return;
                }

                setRequestError('An error occurred while resetting password. Please try again later.');
            })
        }

        setIsLoading(false);
    }

    const getButtonText = () => {
        if (isSuccess) {
            return <ButtonText>Email sent <Icon path={mdiCheckCircleOutline} /></ButtonText>
        }

        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Send Password Reset';
    }

    return (
        <Container>
            { requestError && <StyledAlert message={requestError} type="error"/> }

            <Title>Forgot your password?</Title>

            <Info>That's ok. It happens! Provide the email address associated with your account and we'll send you a password reset email.</Info>

            <form onSubmit={handleSubmit(onSubmit)}>
                {errors.email && <InputError>This field is required.</InputError>}
                <Input name="email" label="Email" type="email" size="small" inputRef={register({ required: true })}/>

                <StyledButton variant="contained" fullWidth type="submit" size="large" disableElevation disabled={isDisabled}>
                    {getButtonText()}
                </StyledButton>

                <Help>If you do not receive the password reset email within a few minutes of sending, please check your Junk/Spam folder just in case the email got delivered there instead of your inbox.</Help>
            </form>
        </Container>
    );
};

export default ForgotPasswordForm;
